import React, { Component, Fragment, useEffect, useState } from "react";
// import ProductHeader from "./ProductHeader";
import companyLogo from "../assets/image/2k_logo.svg";
import Goback from "../assets/image/GoBack.svg";
import { json, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import vinmar from "../assets/image/vinmarPools.jpg";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Navigate } from "react-router-dom";
import moment from "moment/moment";
import { getCustomerRegister } from "../api/GlobalApi";
import { useQuery } from "react-query";

export default function InvoiceProduct(props) {
  const componentRef = useRef();
  const ShareRef = useRef();
  const [sessionValue, setSessionValue] = useState([]);
  const [userFormData, setUserFormData] = useState([]);
  const user = sessionStorage.getItem("data");
  const headOfficeAddress = sessionStorage.getItem("head");
  const subHeadOfficeAddress = sessionStorage.getItem("sub");
  const subHeadAddress = JSON.parse(subHeadOfficeAddress);
  // console.log('subHeadAddress',subHeadAddress);

  console.log("sessionValue", userFormData);

  useEffect(() => {
    const savedObjectString = sessionStorage.getItem("myValue");
    if (savedObjectString) {
      setSessionValue(JSON.parse(savedObjectString));
      console.log("savedObjectString", sessionValue);
    }
  }, []);

  // let malePerson = 0;
  // let femalePerson = 0;
  // let supervisorPerson = 0;
  // let malePresent = 0;
  // let femalePresent = 0;
  // let supervisorPresent = 0;
  // // let managementFees = 0
  // let gstTax = 0;

  const [isRemove, setIsRemove] = useState(false);
  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "TAX INVOICE",
  });

  let presentDays = 0;
  let subTotals = 0;
  let managementFee = 0;
  let managementTotal = 0;
  let gstTaxs = 0;

  sessionValue?.userForm?.map((item, i) => {
    // presentDays = Number(sessionValue?.userForm[i]?.presentDay) + presentDays)

    presentDays = Number(sessionValue?.userForm[i]?.presentDay) + presentDays;
    subTotals = Number(sessionValue?.userForm[i]?.subTotal) + subTotals;

    // console.log('managementFeeSubTotals',managementFee);

    // Number(sessionValue?.userForm[i]?.managementFee) + managementFee;
    // gst = Number(sessionValue?.userForm[i]?.gstTax) + gst;
  });
  managementFee = (10 * subTotals) / 100;
  managementTotal = subTotals + managementFee;
  gstTaxs = (18 * managementTotal) / 100;
  const totalRs = subTotals + managementFee;
  const grandTotal = totalRs + gstTaxs;
  console.log("gstTaxs", gstTaxs);

  // const options = { maximumFractionDigits: 2 };
  const formattedsubTotals = new Intl.NumberFormat("en-IN").format(subTotals.toFixed(0));
  const formattedManagementPrice = new Intl.NumberFormat("en-IN").format(
    managementFee.toFixed(0)
  );
  const formattedManagementTotal = new Intl.NumberFormat("en-IN").format(
    managementTotal.toFixed(0)
  );
  const formattedGstPrice = new Intl.NumberFormat("en-IN").format(gstTaxs.toFixed(0));
  const formattedGstTotalPrice = new Intl.NumberFormat("en-IN").format(
    grandTotal.toFixed(0)
  );
  // const grandTotal = gstTaxs + totalRs;
  const handleShare = () => {
    const shareInvoice = componentRef.current;

    html2canvas(shareInvoice).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 10;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("Invoice.pdf");
      console.log("imgData", imgData);
      console.log("pdf", pdf);
      console.log("pdfWidth", pdfWidth);
      console.log("pdfHeight", pdfHeight);
      console.log("imgWidth", imgWidth);
      console.log("imgHeight", imgHeight);
      console.log("ratio", ratio);
      console.log("imgX", imgX);
      console.log("imgY", imgY);
    });
  };
  useEffect(() => {
    if (isRemove === true) {
      sessionStorage.removeItem("myValue");
      // navigate("/Billing/BillingTable");
    }
  }, [isRemove]);
  const tableStyle = {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "100%",
  };

  const thStyle = {
    border: "1px solid black",
    textAlign: "center",
    padding: "8px",
  };

  const tdStyle = {
    border: "1px solid black",
    padding: "8px",
  };
  const GetcustomerRegister = useQuery(
    "customerResisterTable",
    getCustomerRegister
  );
  let details =
    GetcustomerRegister &&
    GetcustomerRegister?.data &&
    GetcustomerRegister?.data?.data
      ? GetcustomerRegister &&
        GetcustomerRegister?.data &&
        GetcustomerRegister?.data?.data
      : [];
  const headOffice = details?.filter((item) => {
    return headOfficeAddress === item?.shopName;
  });
  const subHeadOffice = details?.filter((item) => {
    return subHeadAddress?.headOff === item?.shopName;
  });
  console.log("subHeadOffice", subHeadOffice);

  // console.log("subOfficeName", subOfficeName);
  // const SubHeadOffice = headOfficeList?.filter((item) => {
  //   return subOfficeName[0]?.headoffice === item.shopName;
  // });

  return (
    <>
      <div>
        <div className="invoice-container">
          <div className="billing_invoice_header">
            <div>
              <img src={Goback} alt="go back" onClick={() => navigate(-1)} />
            </div>

            <div className="invoice_head_section">
              <div>
                <h3 className="billing_invoice_heading">Salary Invoice</h3>
              </div>

              <div>
                <input
                  type="submit"
                  value="Print"
                  className="billing_print_btn"
                  onClick={() => (handlePrint(), setIsRemove(!isRemove))}
                />
                <input
                  type="submit"
                  value="Download"
                  className="billing_print_btn"
                  onClick={() => (handleShare(), setIsRemove(!isRemove))}
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div
              className="row invoiceMainContainer my-5 px-3"
              ref={componentRef}
            >
              <div className="col-lg-12 ps-3 m-0">
                <div className="col-12 d-flex justify-content-center py-2">
                  <h2 className="tax_heading">TAX INVOICE</h2>
                </div>
                <div className="col-12 d-flex tax_address_block  flex-wrap ">
                  {/* <div className="col-6 d-flex flex-column align-items-start justify-content-center flex-wrap">
                    <h4 className="vinmar_heading">VINMAR POOLS</h4>
                    <span className="vinmar_address">477,EAST COAST ROAD</span>
                    <span className="vinmar_address">KANATHUR</span>
                    <span className="vinmar_address">CHENNAI 603112</span>
                    <span className="vinmar_address">GST NO 33CAHPS1111H1Z3</span>
                    <span className="vinmar_address">CONTACT - 9840385085</span>
                    <span className="vinmar_address">
                      E-MAIL:{" "}
                      <span style={{ color: "blue", fontWeight: "600" }}>
                        vinmarpoolsindia@gmail.com
                      </span>{" "}
                    </span>
                    <span>
                      {" "}
                      <p className="vinmar_address">
                        WEBSITE:{" "}
                        <span style={{ color: "blue", fontWeight: "600" }}>
                          WWW.VINMARPOOLS.COM
                        </span>
                      </p>
                    </span>
                  </div> */}

                  <div className=" col-7 d-flex flex-column align-items-start justify-content-center flex-wrap">
                    {/* {sessionValue?.subOffice !== null && (
                      <p className="vinmar_office_Type m-0">HEAD OFFICE :</p>
                    )} */}

                    <h4 className="vinmar_heading mb-0">
                      {(headOffice[0] || subHeadOffice[0])?.shopName}
                      {/* {headOffice[0]?.shopName} */}
                    </h4>
                    <span className="vinmar_address">
                      {(headOffice[0] || subHeadOffice[0])?.companyAddress}
                    </span>
                    <span className="vinmar_address">
                      {(headOffice[0] || subHeadOffice[0])?.companyPincode}
                    </span>
                    <span className="vinmar_address">
                      GST NO {(headOffice[0] || subHeadOffice[0])?.gstNumber}
                    </span>
                    <span className="vinmar_address">
                      CONTACT -{" "}
                      {(headOffice[0] || subHeadOffice[0])?.customerPhno}
                    </span>
                    <p className="vinmar_address mb-0">
                      E-MAIL:{" "}
                      <span style={{ color: "blue", fontWeight: "600" }}>
                        {(headOffice[0] || subHeadOffice[0])?.emailId}
                      </span>{" "}
                    </p>
                    <span>
                      {" "}
                      <p className="vinmar_address mb-0">
                        WEBSITE:{" "}
                        <span style={{ color: "blue", fontWeight: "600" }}>
                          {(headOffice[0] || subHeadOffice[0])?.website}
                        </span>
                      </p>
                    </span>
                  </div>
                  <div className="col-5 pb-2 pe-2 d-flex justify-content-end">
                    <img
                      style={{
                        width: "200px",
                        height: "150px",
                        borderRadius: "20px",
                        // marginLeft: "60px",
                      }}
                      src={`data:image/jpeg;base64,${user}`}
                      alt="logo"
                    />
                  </div>
                </div>
                <div className="col-12 px-3 pt-2 d-flex justify-content-between">
                  <span className="vinmar_from">TO</span>
                  <div className="d-flex flex-column">
                    <span className="vinmar_from">
                      {" "}
                      DATE: {sessionValue?.date}
                    </span>
                    <span className="vinmar_from">
                      INV NO: {sessionValue?.invoiceEntry}
                    </span>
                  </div>
                </div>
                <div className="col-6 d-flex">
                  <div className="col-1"></div>
                  <div className="col-10 d-flex flex-column justify-content-center">
                    <span className="vinmar_from">
                      {sessionValue?.customerName}
                    </span>
                    <span className="vinmar_from">
                      {sessionValue?.customerAddress},
                    </span>
                    <span className="vinmar_from">
                      {sessionValue?.district} - {sessionValue?.pincode}
                    </span>
                    {/* <span className="vinmar_from">
                      CONTACT - {sessionValue?.contactNumber}
                    </span> */}
                  </div>
                </div>
                <div className="col-12 pt-3 pb-1 px-3 d-flex flex-column justify-content-center">
                  <span className="vinmar_from">
                    {" "}
                    SUB: GARDEN MAINTENANCE INVOICE -{" "}
                    {moment().format("MMMM YYYY")}
                  </span>
                  <span className="vinmar_from pt-2">
                    WE ARE PLEASED TO INVOICE FOR COMMON LANDSCAPE GARDEN
                    REGULAR MAINTENANCE WORK- {moment().format("MMMM YYYY")}
                  </span>
                </div>
                <div className="col-12 px-3 pb-2">
                  <div className="table-responsive">
                    <table style={tableStyle}>
                      <thead>
                        <tr>
                          <th className="vinmar_from" style={thStyle}>
                            DISCRIPTION OF GOODS
                          </th>
                          <th className="vinmar_from" style={thStyle}>
                            UNIT
                          </th>
                          <th className="vinmar_from" style={thStyle}>
                            SALERY OF PER DAY
                          </th>
                          <th className="vinmar_from" style={thStyle}>
                            TOTAL AMOUNT
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="ps-3">
                            {sessionValue?.userForm?.map((items, i) => {
                              return (
                                <div className="d-flex flex-column align-items-end pe-3   vinmar_from">
                                  {items?.descriptionOfGoods}
                                </div>
                              );
                            })}
                          </td>
                          <td style={tdStyle}>
                            {sessionValue?.userForm?.map((item, i) => {
                              return (
                                <div className="d-flex flex-column justify-content-center  align-items-center vinmar_from">
                                  <span> {item?.presentDay}</span>
                                </div>
                              );
                            })}
                          </td>
                          <td style={tdStyle}>
                            {sessionValue?.userForm?.map((item, i) => {
                              return (
                                <div className="d-flex flex-column align-items-center vinmar_from">
                                  <span>{item?.salaryPerDay}</span>
                                </div>
                              );
                            })}
                          </td>
                          <td>
                            {sessionValue?.userForm?.map((items, i) => {
                              return (
                                <div className="pe-2 d-flex flex-column align-items-center vinmar_from">
                                  <span>
                                    {" "}
                                    {Number(items?.presentDay) *
                                      Number(items?.salaryPerDay)}{" "}
                                  </span>
                                </div>
                              );
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan="3"> </td>
                          <td
                            className="text-center vinmar_from"
                            style={tdStyle}
                            rowSpan="3"
                          >
                            {presentDays}
                          </td>
                          <td style={tdStyle} className="vinmar_from">
                            <div className="d-flex flex-column align-items-end">
                              <span>SUB TOTAL </span>
                              <span>MANAGEMENT FEES 10%</span>
                              {/* <span>10%</span> */}
                            </div>
                          </td>
                          <td style={tdStyle} className="vinmar_from">
                            <div className="d-flex flex-column align-items-center">
                              <span>{formattedsubTotals}</span>
                              {/* <span>{managementFee}</span> */}
                              <span>{formattedManagementPrice}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          {/* <td style={tdStyle}></td> */}
                          <td style={tdStyle} className="vinmar_from">
                            <div className=" d-flex flex-column align-items-end">
                              <span>TOTAL </span>
                              <span> GST TAX 18%</span>
                            </div>
                          </td>
                          <td style={tdStyle} className="vinmar_from">
                            <div className="d-flex flex-column align-items-center">
                              <span>{formattedManagementTotal}</span>
                              <span>{formattedGstPrice}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          {/* <td style={tdStyle}></td> */}
                          <td style={tdStyle} className="vinmar_from text-end">
                            GRAND TOTAL
                          </td>
                          <td
                            style={tdStyle}
                            className="vinmar_from text-center"
                          >
                            {formattedGstTotalPrice}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-12 px-3 d-flex flex-column">
                  <span className="vinmar_from">
                    We declare that this document details show the actual price
                    of the goods described and that all particular are true and
                    correct
                  </span>
                  <div className="d-flex flex-column align-items-end justify-content-end">
                    <span className="vinmar_from pb-5 pt-3">VINMAR POOLS</span>
                    <span className="vinmar_from pt-3"> M.SHANKAR RAJAN</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

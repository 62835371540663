import React from "react";

const BillingAddInputField = (props) => {
  return (
    <div>
      <input
        //  style={{

        //                   }}
        type={props.type}
        placeholder={props.placeholder}
        {...props.field}
        className="billing_input_field"
        style={
          props?.fieldState && props?.fieldState?.error
            ? { borderColor: "red" }
            : {}
        }
        list={props.list}
      ></input>
    </div>
  );
};

export default BillingAddInputField;

import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputField from "../component/InputField";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { addCart } from "../redux/action";
import { login } from "../redux/reducer/handleCart";
import axios from "axios";
import { useMutation } from "react-query";
import stock from "../assets/image/stock.png";
import companyLogo from "../assets/image/2k_logo.svg";
export default function SignIn({ setValue }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [type, setType] = useState("password");
  const [error, setError] = useState();
  const [inActiveError, setInActiveError] = useState();
  //  const navigate = useNavigate()
  const { state } = useLocation();

  const passwordType = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(`${process.env.REACT_APP_HOST}/companies/login`, postData),
    {
      onSuccess: (data) => {
        console.log(
          "data",
          data &&
            data?.data &&
            data?.data?.company &&
            data?.data?.company?.status
        );
        if (
          data &&
          data?.data &&
          data?.data?.company &&
          data?.data?.company?.status === "active"
        ) {
          sessionStorage.setItem("login", true);
          sessionStorage.setItem(
            "user",
            data &&
              data?.data &&
              data?.data?.company &&
              data?.data?.company?.role
          );
          sessionStorage.setItem(
            "data",
            data &&
              data?.data &&
              data?.data?.company &&
              data?.data?.company?.companyLogo
          );
          sessionStorage.setItem(
            "name",
            data &&
              data?.data &&
              data?.data?.company &&
              data?.data?.company?.shopName
          );
          if (
            data &&
            data?.data &&
            data?.data?.company &&
            data?.data?.company?.companyType === "headOffice"
          ) {
            sessionStorage.setItem(
              "head",
              data &&
                data?.data &&
                data?.data?.company &&
                data?.data?.company?.shopName
            );
          }
          if (
            data &&
            data?.data &&
            data?.data?.company &&
            data?.data?.company?.companyType === "subOffice"
          ) {
            const subCompany = {
              subOff:
                data &&
                data?.data &&
                data?.data?.company &&
                data?.data?.company?.shopName,
              headOff:
                data &&
                data?.data &&
                data?.data?.company &&
                data?.data?.company?.headoffice,
            };
            const setSessionData = JSON.stringify(subCompany)
            sessionStorage.setItem("sub", setSessionData);
            // sessionStorage.setItem(
            //   "subHead",
            //   data &&
            //     data?.data &&
            //     data?.data?.company &&
            //     data?.data?.company?.
            // );
          }

          dispatch(
            login({
              data: data && data?.data && data?.data?.company,
              login: true,
            })
          );
        } else {
          setInActiveError(
            "This id was Inactive please contact to 2k virtual world support"
          );
          // navigate('/')
        }
      },
      onError: (error) => {
        // Handle error
        setError(error);
        console.log(
          "Mutation failed:",
          error,
          error?.response &&
            error?.response?.data &&
            error?.response?.data?.message
        );
      },
    }
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = (data) => {
    mutate(data);
  };
  // useEffect(() => {
  //   localStorage.setItem("login", true);
  // }, []);
  const [data, setData] = useState(localStorage.getItem("login") || "");
  console.log("LOGINPage", isError, error);
  return (
    <div className="SignIn-mainContainer">
      <div className="signIn-inner-container">
        <div className="signIn-header">
          <div>
            {" "}
            <img
              className="signIn-companyLogo"
              src={companyLogo}
              alt="companyLogo"
            />{" "}
          </div>
          <div className="signIn-companyName">2K Inventory</div>
        </div>
        <div className="signIn-input-container">
          <form
            onSubmit={handleSubmit((data) => {
              handleClick(data);
            })}
            className="singIn-input-form"
          >
            <div style={{ marginBottom: "25px" }}>
              <label className="signIn-labelName">E-Mail:</label>
              <Controller
                control={control}
                name="username"
                render={(field) => (
                  <InputField {...field} name="--model" type="text" />
                )}
              />
            </div>
            <div>
              <label className="signIn-labelName">Password:</label>
              <Controller
                control={control}
                name="password"
                render={(field) => (
                  <InputField {...field} name="--model" type={type} />
                )}
              />
            </div>
            <div onClick={passwordType} style={{ marginBottom: "30px" }}>
              <input type="checkBox" checked={type == "text" ? "true" : ""} />{" "}
              Show Password
            </div>
            <div>
              <button className="signIn-button" type="submit">
                {isLoading ? (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Login"
                )}
              </button>
            </div>
            <div
              style={{
                textAlign: "end",
                color: "blue",
                textDecoration: "underLine",
                cursor: "pointer",
                paddingRight: "20px",
                paddingTop: "8px",
              }}
              onClick={() => {
                // sessionStorage.setItem("myValue", JSON.stringify());
                navigate("/UserRegister");
              }}
            >
              New Register
            </div>
            <div>
              {" "}
              {isError && (
                <p className="signIn_error">
                  {error &&
                    error?.response &&
                    error?.response?.data &&
                    error?.response?.data?.message}
                </p>
              )}
              {inActiveError && <p className="signIn_error">{inActiveError}</p>}
            </div>
          </form>
        </div>
      </div>
      <div>
        <img src={stock} alt="stockImage" style={{ width: "90%" }} />
      </div>
    </div>
  );
}
// const mapDispatchToProps = (dispatch) => ({
//   setValue: (value) => dispatch({ type: "SET_VALUE", payload: value }),
// });
// connect(null, mapDispatchToProps);

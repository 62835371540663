import React, { useEffect, useState } from "react";
import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray,
} from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import Logo from "/assets/image/Go Back.svg";
import Goback from "../assets/image/GoBack.svg";
import DELETE from "../assets/image/Delete.svg";
// import Add from "../assets/image/Add.svg";
// import {useQueryClient, useMutation, useQuery} from '@tanstack/react-query';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BillingAddInputField from "./BillingAddInputField";

// import Select from "react-select";
import {
  Alert,
  Autocomplete,
  FormControl,
  InputLabel,
  List,
  TextField,
} from "@mui/material";
import {
  SharePurchaseForm,
  UsePurchaseForm,
  getPurchaseProduct,
} from "../api/GlobalApi";
// import { Axios } from "axios";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import DatePicker from "react-datepicker";
import deleteIcon from "../assets/image/deleteIcon.svg";
import Add from "../assets/image/Add.svg";
import AlertModal from "./AlertModal";
const PurchaseForm = (props) => {
  const [productAdd, setProductAdd] = useState("");
  const [open, setOpen] = useState(false);
  const [UpdateOpen, setUpdateOpen] = useState(false);
  console.log("productAdd", productAdd);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userForm: [
        {
          productName: "",
          quantity: "",
          purchaseAmount: "",
          transport: "",
          gst: "",
          otherAmount: "",
          total: "",
          purchaseDate: "",
          invoiceNumber: "",
          transactionMode: "",
          transactionStatus: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "userForm",
    control,
  });

  const navigate = useNavigate();
  const { state } = useLocation();

  console.log("State_Form", state);

  // const onSubmit = async(data) => {
  //   await UsePurchaseForm(data);
  //   console.log("data_PUR", data);
  //   // navigate("/BillingInvoice");
  // };

  useEffect(() => {
    Array(state).map((ele, i) => {
      setValue(`userForm.${i}.productName`, ele && ele?.productName);
      setValue(`userForm.${i}.quantity`, ele && ele?.quantity);
      setValue(`userForm.${i}.purchaseAmount`, ele && ele?.purchaseAmount);
      setValue(`userForm.${i}.transport`, ele && ele?.transport);
      setValue(`userForm.${i}.gst`, ele && ele?.gst);
      setValue(`userForm.${i}.otherAmount`, ele && ele?.otherAmount);
      setValue(`userForm.${i}.total`, ele && ele?.total);
      setValue(`userForm.${i}.invoiceNumber`, ele && ele?.invoiceNumber);
      setValue(`userForm.${i}.transactionMode`, ele && ele?.transactionMode);
      setValue(
        `userForm.${i}.transactionStatus`,
        ele && ele?.transactionStatus
      );
      setValue(`userForm.${i}.purchaseDate`, ele && ele?.purchaseDate);
    });
  }, [state]);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    // First argument: function that makes the actual request
    // Second argument (optional): Config object
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/purchases/addPurchase`,
        postData
      ),
    {
      // You can define callbacks for different stages of the mutation
      onSuccess: (data) => {
        console.log("Data_formate", data?.data);
        // Handle successful mutation
        // alert("Purchase Added successful!");
        // navigate("/Purchase/PurchaseTable");
        setOpen('Purchase details has been successfully added.');
      },
      onError: (error) => {
        // Handle error
        console.error("Mutation failed:", error);
      },
    }
  );
  const updata = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/purchases/${state?.id}`,
        postData
      ),
    {
      onSuccess: () => {
        // alert("Purchase Update successful!");
        // navigate("/Purchase/PurchaseTable");
        setUpdateOpen("Purchase details has been successfully updated.");
      },
      onError: (error) => {
        // Handle error
        console.error("Mutation failed:", error);
      },
    }
  );
  const getPurchaseProducts = useQuery(
    "getPurchaseProduct",
    getPurchaseProduct
  );
  //   const { data, isRefetching } = useQuery(
  //     'viewSubTicket',
  //     getPurchaseProduct,
  //   )
  //  const productData = data&&data?.data
  //  console.log('productData',productData);
  // const {data} = useQuery(axios.get(
  //   `${process.env.REACT_APP_HOST}/products/list`,
  // );)
  // const newArray = da.
  // console.log("getPurchaseProducts", data);
  const onSubmit = async (data) => {
    console.log("PurchangeForm", data);
    if (state && state?.id) {
      updata.mutate(data?.userForm[0]);
      // console.log("upData", data?.userForm[0]);
    } else {
      // data?.userForm.map((ele) => {
      mutate(data?.userForm);
      // console.log("welcome",ele );
      // });
    }
  };

  let {
    purchaseAmount,
    transport,
    otherAmount,
    gst,
    quantity,
    transactionMode,
    transactionStatus,
  } = watch();
  useEffect(() => {
    if (
      purchaseAmount !== undefined &&
      transport !== undefined &&
      otherAmount !== undefined &&
      gst !== undefined
    ) {
      let a =
        // Number(purchaseAmount) +
        Number(transport) + Number(otherAmount) + Number(gst);
      setValue("total", a + quantity * Number(purchaseAmount));
    } else {
    }
    // setValue(
    //   `userForm.${index}.transactionMode`,
    //   transactionMode == "debit" ? "debit" : "Completed"
    // );
  }, [
    purchaseAmount,
    transport,
    otherAmount,
    gst,
    quantity,
    transactionMode,
    transactionStatus,
  ]);

  const top100Films =
    getPurchaseProducts.data &&
    getPurchaseProducts?.data?.data?.filter((item) => item !== null);

  console.log("top100Films", top100Films);

  useEffect(() => {
    if (productAdd === "add") {
      navigate("/ProductView/ProductForm");
    }
  }, [productAdd]);

  const watchFields = watch("userForm");
  console.log("watchFields", watchFields);

  // const calculateQuantity = (index) => {
  //   const quantity = watchFields[index]?.quantity || 0;
  //   const purchaseAmount = watchFields[index]?.purchaseAmount || 0;
  //   const subTotal = (Number(quantity) * Number(purchaseAmount));
  //   setValue(`userForm.${index}.total`, subTotal.toString());
  // };
  // const calculateTotalAmount = (index) => {
  //   const salaryPerDay = watchFields[index]?.subTotal || 0;
  //   const presentDay = watchFields[index]?.managementFee || 0;
  //   const subTotal = Number(salaryPerDay) + Number(presentDay);
  //   console.log("KKDDDLL", index, salaryPerDay, presentDay, subTotal);
  //   setValue(`userForm.${index}.totalRs`, subTotal.toString());
  // };
  const calculateSubTotal = (index) => {
    const purchaseAmount = watchFields[index]?.purchaseAmount || 0;
    const transport = watchFields[index]?.transport || 0;
    const gst = watchFields[index]?.gst || 0;
    const otherAmount = watchFields[index]?.otherAmount || 0;
    const quantity = watchFields[index]?.quantity || 0;

    const subTotal =
      Number(quantity) * Number(purchaseAmount) +
      Number(transport) +
      (Number(gst) *
        (Number(quantity) * Number(purchaseAmount) + Number(transport))) /
        100 +
      Number(otherAmount);
    console.log("test", subTotal);
    setValue(`userForm.${index}.total`, subTotal.toString());
    // setValue(
    //   `userForm.${index}.transactionMode`,
    //   `userForm.${index}.transactionMode` == "debit" ? "completed" : "pending"
    // );
  };
  // const options = top100Films

  // console.log('options',options && options?.props?.value);
  // const options = [
  //   {
  //     value: "add",
  //     label: (
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //         {/* <img
  //           src="path/to/add-icon.png"
  //           alt="Add"
  //           style={{ width: 20, height: 20, marginRight: 10 }}
  //         /> */}
  //         Add
  //       </div>
  //     ),
  //   },
  //   ...top100Films?.map((film) => ({
  //     value: film,
  //     label: (
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //         {/* <img
  //           src="path/to/film-icon.png"
  //           alt={film}
  //           style={{ width: 20, height: 20, marginRight: 10 }}
  //         /> */}
  //         {film}
  //       </div>
  //     ),
  //   })),
  // ];
  const handleChange = (event, value) => {
    console.log("Selected value:", value); // value contains the selected movie object
    setProductAdd(event.target.value);
    // console.log('123:', event); // event object
  };
  return (
    <>
      <div className="main-container">
        <div>
          <div className="BillingAddHeader">
            <div className="Billing-Add-GoBackBtn">
              <img
                src={Goback}
                alt="no-image"
                onClick={() => navigate(-1)}
              ></img>
            </div>

            <div className="billing-heading-viewblock">
              <div>
                <h3 className="Billng-Add-Heading">Purchase Add</h3>
              </div>

              <div className="Billing-Add-ViewBlock">
                <button
                  className="Billing-Add-ViewBtn"
                  onClick={() => {
                    navigate("/Purchase/PurchaseTable");
                  }}
                >
                  View
                </button>
              </div>
            </div>
          </div>
          <div className="BillingAddMainBlock"  style={{height:"80vh", overflowY: "hidden" }}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="PurchaseAddFormBlock px-5"
            >
              {fields.map((field, index) => {
                return (
                  <div key={field.id} >
                    
                    <div>
                      <div className="purchaseAddInputField">
                        <label className="BillingAddInputLabel">
                          Select Product
                          <span style={{ color: "red", fontSize: "14px" }}>
                            *
                          </span>
                        </label>
                        <div className="d-flex">
                          <Controller
                            control={control}
                            name={`userForm.${index}.productName`}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <Autocomplete
                                value={value}
                                onChange={(event, newValue) => {
                                  onChange(newValue); 
                                }}
                                className="purchaseSelect_input_field css-2avex9-MuiAutocomplete-root .MuiOutlinedInput-root"
                                disablePortal
                                id="combo-box-demo"
                                options={
                                  top100Films && top100Films ? top100Films : []
                                }
                                sx={{ height: 40 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => onChange(e.target.value)}
                                    error={!!error}
                                  />
                                )}
                              />
                            )}
                            rules={{ required: true }}
                          />

                          {/* <Controller
                            control={control}
                            // name="quantity"
                            name={`userForm.${index}.productName`}
                            render={({ field: { onChange, value } }) => (

                              <Autocomplete
                              // value={value}
                              // onChange={onChange}
                              // onChange={(e) => {
                              //   onChange(e);
                              //   handleChange(e);
                              // }}
                                // onChange={handleChange}
                                className="purchaseSelect_input_field css-2avex9-MuiAutocomplete-root .MuiOutlinedInput-root"
                                disablePortal
                                id="combo-box-demo"
                                options={top100Films}
                                sx={{ height: 40 }}
                                renderInput={(vals) => (
                                  <TextField onChange={onChange} {...vals} />
                                )}
                              />
                             
                            )}
                            rules={{ required: true }}
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="purchaseAddInputField ">
                        <label className="BillingAddInputLabel">
                          Quantity{" "}
                          <span style={{ color: "red", fontSize: "14px" }}>
                            *
                          </span>
                        </label>
                        <Controller
                          control={control}
                          // name="quantity"
                          name={`userForm.${index}.quantity`}
                          // render={({ field: { onChange, value } }) => (
                            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                            <input
                              {...field}
                              // placeholder="Quantity"
                              style={
                                error ? { borderColor: "red" } : {}
                              }
                              className="billing_input_field"
                              type="number"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                calculateSubTotal(index);
                              }}
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>

                      <div className="purchaseAddInputField">
                        <label className="BillingAddInputLabel">
                          Product Amount
                          <span style={{ color: "red", fontSize: "14px" }}>
                            *
                          </span>
                        </label>
                        <Controller
                          control={control}
                          // name="purchaseAmount"
                          name={`userForm.${index}.purchaseAmount`}
                          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                            <input
                              {...field}
                              // placeholder="Product Amount"
                              style={
                                error
                                  ? { borderColor: "red" }
                                  : {}
                              }
                              className="billing_input_field"
                              type="number"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                // calculateQuantity(index);
                                calculateSubTotal(index);
                              }}
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>

                      <div className="purchaseAddInputField">
                        <label className="BillingAddInputLabel">
                          Transport
                        </label>
                        <Controller
                          control={control}
                          // name="transport"
                          name={`userForm.${index}.transport`}
                          render={({ field: { onChange, value } }) => (
                            <input
                              {...field}
                              // placeholder="Transport"
                              className="billing_input_field"
                              type="number"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                calculateSubTotal(index);
                              }}
                            />
                          )}
                        />
                      </div>

                      <div className="purchaseAddInputField">
                        <label className="BillingAddInputLabel">GST</label>
                        <Controller
                          control={control}
                          // name="gst"
                          name={`userForm.${index}.gst`}
                          render={({ field: { onChange, value } }) => (
                            <input
                              {...field}
                              // placeholder="GST %"
                              className="billing_input_field"
                              type="number"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                calculateSubTotal(index);
                              }}
                            />
                          )}
                        />
                      </div>

                      <div className="purchaseAddInputField">
                        <label className="BillingAddInputLabel">
                          Other Amount
                        </label>
                        <Controller
                          control={control}
                          // name="otherAmount"
                          name={`userForm.${index}.otherAmount`}
                          render={({ field: { onChange, value } }) => (
                            <input
                              {...field}
                              // placeholder="Other Amt"
                              className="billing_input_field"
                              type="number"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                calculateSubTotal(index);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="purchaseAddInputField">
                        <label className="BillingAddInputLabel">
                          Total Amount
                        </label>
                        <Controller
                          control={control}
                          // name="total"
                          name={`userForm.${index}.total`}
                          render={({ field: { onChange, value } }) => (
                            <input
                              {...field}
                              type="number"
                              className="billing_input_field"
                              // placeholder="Total Amt"
                              // value={value}
                              onChange={(e) => {
                                onChange(e);
                                calculateSubTotal(index);
                              }}
                              value={value}
                            />
                          )}
                        />
                      </div>
                    </div>
                    {/* <div
                  className="BillingAddInputContainer"
                  style={{ justifyContent: "space-between", width: "90%" }}
                > */}
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "2px dashed black",
                        paddingBottom: "8px",
                      }}
                    >
                      

                      <div
                        className="purchaseAddInputField"
                        // style={{ marginLeft: "27px" }}
                      >
                        <label className="BillingAddInputLabel">
                          Purchase Date
                        </label>
                        <Controller
                          control={control}
                          // name="purchaseDate"
                          name={`userForm.${index}.purchaseDate`}
                          render={({ field }) => (
                            <DatePicker
                              className="billing_input_field "
                              {...field}
                              // placeholderText="PurchaseDate"
                              selected={field.value} // Value from React Hook Form
                              onChange={(date) => field.onChange(date)}
                              maxDate={new Date()}
                              // Event handler for onChange
                            />
                          )}
                        />
                      </div>

                      <div className="purchaseAddInputField">
                        <label className="BillingAddInputLabel">
                          Invoice No
                        </label>
                        <Controller
                          control={control}
                          // name="invoiceNumber"
                          name={`userForm.${index}.invoiceNumber`}
                          render={(field) => (
                            <BillingAddInputField
                              {...field}
                              // placeholder="Invoiceno"
                              type="text"
                            />
                          )}
                        />
                      </div>

                      <div className="purchaseAddInputField">
                        <label className="BillingAddInputLabel">
                          Transaction Mode
                          <span style={{ color: "red", fontSize: "14px" }}>
                            *
                          </span>
                        </label>
                        <Controller
                          control={control}
                          // name="transactionMode"
                          name={`userForm.${index}.transactionMode`}
                          // render={({
                          //   field: { onChange, onBlur, value, ref },
                          // }) => (
                            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                            <select
                              value={value}
                              onChange={onChange}
                              className="text-capitalize billing_input_field"
                              // placeholder="Select"
                              // style={{
                              //   "text-transform": "capitalize",
                              //   // borderRadius: "5px",
                              //   // border: "none",
                              //   // padding: "5px 10px",
                              // }}
                              style={
                                error
                                  ? { borderColor: "red" }
                                  : {}
                              }
                            >
                              <option value=""></option>
                              <option value="Online">Online</option>
                              <option value="Cash">Cash</option>
                              <option value="debit">debit</option>
                            </select>
                          )}
                          rules={{ required: true }}
                        />
                      </div>

                      <div className="purchaseAddInputField">
                        <label className="BillingAddInputLabel">
                          Transaction Status
                          <span style={{ color: "red", fontSize: "14px" }}>
                            *
                          </span>
                        </label>
                        <Controller
                          control={control}
                          // name="transactionStatus"
                          name={`userForm.${index}.transactionStatus`}
                          // render={({
                          //   field: { onChange, onBlur, value, ref },
                          // }) => (
                            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                            <select
                              value={value}
                              onChange={onChange}
                              className="text-capitalize billing_input_field"
                              // style={{
                              //   "text-transform": "capitalize",
                              //   // borderRadius: "5px",
                              //   // border: "none",
                              //   // padding: "5px 10px",
                              // }}
                              style={
                                error
                                  ? { borderColor: "red" }
                                  : {}
                              }
                            >
                              <option value=""></option>
                              <option value="Pending">Pending</option>
                              <option value="Completed" selected>
                                Completed
                              </option>
                            </select>
                          )}
                          rules={{ required: true }}
                        />
                      </div>

                      <div
                        style={{ height: "100px", marginLeft: "15px" }}
                        className="d-flex align-items-end"
                      >
                        {/* {index === 1?  */}
                        {/* :<></>} */}
                        <div>
                          {index < 0 ? (
                            <></>
                          ) : (
                            <>
                              <div
                                style={{
                                  height: "100px",
                                  display: "flex",
                                  alignItems: "center",
                                  paddingTop: "15px",
                                }}
                              >
                                <img
                                  src={Add}
                                  alt=""
                                  className=" BillingAdd-AddBtn"
                                  onClick={() => {
                                    append({
                                      productName: "",
                                      quantity: "",
                                      purchaseAmount: "",
                                      transport: "",
                                      gst: "",
                                      otherAmount: "",
                                      total: "",
                                      purchaseDate: "",
                                      invoiceNumber: "",
                                      transactionMode: "",
                                      transactionStatus: "",
                                    });
                                  }}
                                ></img>
                              </div>
                            </>
                          )}
                        </div>
                        <img
                          style={{
                            width: "40px",
                            height: "100px",
                            display: index === 0 ? "none" : "block",
                            alignItems: "center",
                            paddingTop: "15px",
                          }}
                          // style={   `${index === 0 ? 'display:none' : null }`}
                          src={deleteIcon}
                          alt="delete"
                          {...register(`userForm.${index}.image`)}
                          onClick={() => remove(index)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              <div
                style={{
                  padding: "0px 20px 20px",
                  marginBottom: "30px",
                  border: "none",
                }}
              >
                <div>
                  {/* <button
                    type="submit"
                    className="BillingAddSubmitBtn"
                    disabled={isLoading ? true : false}
                  >
                    {}
                    Save
                  </button> */}
                  <button
                    style={{ border: "none" }}
                    className="BillingAddSubmitBtn mt-4 ms-3"
                    disabled={isLoading ? true : false}
                    type="submit"
                  >
                    {isLoading ? (
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <AlertModal
        open={open}
        setOpen={setOpen}
        // handleClose={handleClose}
        setUpdateOpen={setUpdateOpen}
        UpdateOpen={UpdateOpen}
        navigate={"/Purchase/PurchaseTable"}
      />
    </>
  );
};

export default PurchaseForm;
// gst: 0;
// id: 15;
// invoiceNumber: null;
// otherAmount: 0;
// productName: null;
// purchaseAmount: 0;
// purchaseDate: null;
// quantity: 0;
// quantityType: null;
// total: 0;
// transactionMode: null;
// transactionStatus: "Completed";
// transport: 0;

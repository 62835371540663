import React, { useState } from "react";
import firstProduct from "../assets/productViewImages/First-image.png";
// import secondProduct from "../assets/productViewImages/Second-image.png";
// import thirdProduct from "../assets/productViewImages/third-image.png";
// import forthProduct from "../assets/productViewImages/forth-image.png";
import BackIcon from "../assets/productViewImages/Back-icon.png";
// import searchIcon from '../assets/productViewImages/Second-image.png'
import { useForm, SubmitHandler, Controller, set } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ProductHeader from "./ProductHeader";
import { useQuery } from "react-query";
import { getProductDetail } from "../api/GlobalApi";
import { TableRow } from "@mui/material";
export default function ViewProduct() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { data, isLoading, isError } = useQuery(
    "getProductDetail",
    getProductDetail
  );
  let details=data && data?.data
console.log('details',details);
  // const handleSearch = (event) => {
  //   const term = event.target.value;
  //   setSearchTerm(term);
  //   const results =
  //     data && data?.data
  //       ? data?.data.filter(
  //           (item) =>
  //             item.productNameEnglish?.toLowerCase().startsWith(searchTerm?.toLowerCase())
  //         )
  //       : [];
  //   setSearchResults(results);
  // };
  const handleChange=(e)=>{
    setSearchTerm(e.target.value)
  }
  const filteredProducts = details?.filter((product)=>{
    return(
      product.productNameEnglish?.toLowerCase().startsWith(searchTerm?.toLowerCase())
    )
  }
 );

 
  const { ProductSearch } = watch();
  console.log("ProductSearch", ProductSearch);
  // const filteredData = data&&data?.data?data?.data.filter(item =>  (item.productNameEnglish === ProductSearch)):[];

  const navigate = useNavigate();
  const handle = (e) => {
    navigate("/ProductView/ProductForm", { state: e });
  };
  return (
    <div className="ViewProductMainContainer ">
      <ProductHeader back={true} productView={true} label="Product List" />
      <div className="view-product-addNewProduct-container">
        <div
          className="View-product-addNewProduct"
          onClick={() => navigate("/ProductView/ProductForm")}
        >
          ADD NEW PRODUCT
        </div>
        <div className="view-product-search-container">
          <Controller
            control={control}
            name="ProductSearch"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <input
                type="search"
                placeholder="Search"
                value={value}
                className="view-product-search-field"
                onChange={handleChange}
              />
            )}
          />
        </div>
      </div>
      <div
        className="View-product-footer-container"
        // style={{
        //   overflowX:
        //     searchResults.length > 0 || searchTerm.length === 0
        //       ? "scroll"
        //       : "hidden",
        // }}
      >
        {isLoading ? (
          <TableRow style={{width:"100%",display:'flex',justifyContent:'center',alignItems:'center'}}>
          <div class="wrapper">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <span>Loading</span>
    </div>
            {/* <p style={{ color: "#000", fontSize: "18px", fontWeight: 800 }}>
              Loading...
            </p> */}
          </TableRow>
        ) : isError ? (
          <TableRow>
            <p style={{ color: "#000", fontSize: "18px", fontWeight: 800 }}>
              No Network...
            </p>
          </TableRow>
        ) : searchTerm.length === 0 ? (
          data?.data.map((e, i) => {
            return (
              <>
                <div
                  className="View-product-image-container"
                  onClick={() => handle(e)}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="Product-first-image"
                      src={e.productImage}
                      alt="FirstProducts"
                      style={{ width: "100%",height:'100%' }}
                    />
                  </div>
                  <div className="image-text-container">
                    <div>{e.productNameEnglish}</div>
                  </div>
                </div>
              </>
            );
          })
        ) : filteredProducts.length !== 0 ? (
          filteredProducts.map((e, i) => {
            return (
              <>
                <div
                  className="View-product-image-container"
                  onClick={() => handle(e)}
                >
                  <img
                    className="Product-first-image"
                    src={e.productImage}
                    alt="FirstProducts"
                  />
                  <div className="image-text-container">
                    <div>{e.productNameEnglish}</div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <p className="View-product-NoRecord">No Record Founded</p>
        )}
      </div>
      {/* <div
        className="View-product-addNewProduct"
        onClick={() => navigate("/ProductView/ProductForm")}
      >
        ADD NEW PRODUCT
      </div> */}
    </div>
  );
}
